define('new-sounds-web-client/components/person-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['person-info'],
    src: Ember.computed('image', function () {
      return Ember.String.htmlSafe('' + Ember.get(this, 'image'));
    })
  });
});