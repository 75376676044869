define("new-sounds-web-client/routes/show-detail/page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),

    model: function model(_ref) {
      var _ref$page = _ref.page,
          page = _ref$page === undefined ? 1 : _ref$page;

      var model = this.modelFor('show-detail');
      var fetch = Ember.run.bind(this, this.actions.fetch);
      return Ember.RSVP.hash({
        show: model.show,
        episodes: fetch({ pageSize: 12, page: page, slug: model.show.get('slug') })
      });
    },
    afterModel: function afterModel(model, transition) {
      var parentController = this.controllerFor('show-detail');
      var totalPages = Ember.get(model.episodes, 'meta.pagination.pages');
      var currentPage = Ember.get(model.episodes, 'meta.pagination.page');
      parentController.set('totalPages', totalPages);
      parentController.set('currentPage', currentPage);

      if (transition.params['show-detail'] && transition.params['show-detail'].slug) {
        if (currentPage < totalPages) {
          Ember.get(this, 'headData').set('nextPage', this.router.generate('show-detail.page', transition.params['show-detail'].slug, currentPage + 1));
        }
        if (currentPage > 1) {
          Ember.get(this, 'headData').set('prevPage', this.router.generate('show-detail.page', transition.params['show-detail'].slug, currentPage - 1));
        }
      }
    },


    actions: {
      fetch: function fetch(_ref2) {
        var slug = _ref2.slug,
            pageSize = _ref2.pageSize,
            page = _ref2.page;

        var episodes = this.store.query("story", {
          show: slug,
          item_type: "episode",
          ordering: "-newsdate",
          page_size: pageSize,
          page: page
        });

        return episodes;
      },
      loading: function loading(transition) {
        var controller = this.controllerFor('show-detail');
        controller.set('currentlyLoading', true);
        transition.promise.finally(function () {
          controller.set('currentlyLoading', false);
        });

        return false; // allows the loading template to be shown
      }
    }
  });
});