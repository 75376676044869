define('new-sounds-web-client/mixins/fetch-news-action', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      fetchNews: function fetchNews(_ref) {
        var _this = this;

        var pageSize = _ref.pageSize,
            page = _ref.page;

        return (0, _getBucket.default)(this.store, 'newsounds-news-feed').then(function (_ref2) {
          var bucketItems = _ref2.bucketItems;

          var featuredSlugs = bucketItems.map(function (i) {
            return i.attributes.slug;
          });

          return _this.store.query('story', { channel: featuredSlugs.join(","), item_type: 'article', ordering: '-newsdate', page_size: pageSize, page: page });
        });
      }
    }
  });
});