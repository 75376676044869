define('new-sounds-web-client/components/site-chrome/site-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['site-header'],
    classNameBindings: ['expandedHeader:mod-expanded']
  });
});