define('new-sounds-web-client/components/comments-list', ['exports', 'nypr-publisher-lib/components/comments-list'], function (exports, _commentsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _commentsList.default;
    }
  });
});