define("new-sounds-web-client/snippets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "color-tinting--usage.hbs": "        <div class=\"styleguide-color-samples\">\n          <div class=\"styleguide-color-sample\" style=\"background-color: {{color-changer dynamic.color -20}}\">\n            {{color-changer dynamic.color -20}}\n          </div>\n          <div class=\"styleguide-color-sample\" style=\"background-color: {{color-changer dynamic.color 0}}\">\n            {{color-changer dynamic.color 0}}\n          </div>\n          <div class=\"styleguide-color-sample\" style=\"background-color: {{color-changer dynamic.color 20}}\">\n            {{color-changer dynamic.color 20}}\n          </div>\n        </div>",
    "dynamic-properties--usage.hbs": "        {{#show-grid-item\n          class = \"styleguide-shows-grid-item\"\n          title=dynamic.title\n          tease=dynamic.tease\n          color=dynamic.color\n          play  = (hash itemPK='show-slug' itemTitle='show title')\n        }}\n          {{#if dynamic.hasPhoto}}\n            {{#link-to 'show-detail' 'show-slug'}}\n              {{progressive-image imageUrl=\"http://via.placeholder.com/500x500/?text=Show+Logo\"}}\n            {{/link-to}}\n          {{/if}}\n        {{/show-grid-item}}",
    "episode-grid-dynamic-properties--usage.hbs": "        {{episode-grid-item\n          class = \"styleguide-shows-grid-item\"\n          showTitle=dynamic.showTitle\n          show=dynamic.show\n          title=dynamic.title\n          date=dynamic.date\n          time=dynamic.time\n          hasAudio=dynamic.audio\n          image=dynamic.image\n          itemPK=dynamic.slug\n          colorKey=dynamic.show\n        }}",
    "fp--usage.hbs": "        {{freestyle-palette\n        colorPalette=colorPalette\n        title= 'New Sounds Color Palette'\n        }}",
    "person-grid-dynamic-properties--usage.hbs": "        {{person-grid-item\n          name=dynamic.title\n          title=dynamic.title\n          bio=dynamic.bio\n          image=dynamic.image\n          slug=dynamic.slug\n        }}",
    "progressive-image-dynamic-properties--usage.hbs": "        {{progressive-image imageUrl=dynamic.imageUrl}}",
    "site-chrome--usage.hbs": "        {{#site-chrome}}\n          Content\n        {{/site-chrome}}",
    "site-footer--usage.hbs": "        {{site-chrome/site-footer}}",
    "site-header--usage.hbs": "        {{site-chrome/site-header}}",
    "site-navigation--usage.hbs": "        {{site-chrome/site-navigation}}",
    "text-body--usage.hbs": "        <p>Here's an example of body copy. No special classes are needed, this is default. It can go on and on and on for as long as it wants.</p>\n        <p>Another paragraph even, right after the last one.</p>",
    "text-body-with-dropcap--usage.hbs": "        <p class=\"u-text-dropcap\">Here's an example of body copy. No special classes are needed, this is default. It can go on and on and on for as long as it wants.</p>\n        <p>Another paragraph even, right after the last one.</p>",
    "typography-sofia--usage.hbs": "        {{freestyle-typeface fontFamily='sofia-pro'}}",
    "u-text-headline--usage.hbs": "        <h1 class=\"u-text-headline\">Headline Text</h1>",
    "u-text-small-caps--usage.hbs": "        <span class=\"u-text-small-caps\">Photo credit: Nic Showfield</span>",
    "u-text-subhead--usage.hbs": "        <h2 class=\"u-text-subhead\">Subhead text</h2>",
    "u-text-subhead-small--usage.hbs": "        <span class=\"u-text-subhead__small\">SMALL SUBHEAD</span>"
  };
});