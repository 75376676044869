define('new-sounds-web-client/components/freestyle-dynamic-input-select-option', ['exports', 'ember-freestyle/components/freestyle-dynamic-input-select-option'], function (exports, _freestyleDynamicInputSelectOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _freestyleDynamicInputSelectOption.default;
    }
  });
});