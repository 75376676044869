define('new-sounds-web-client/controllers/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'pageSize', 'year', 'month', 'day', { ordering: 'sort' }],
    page: 1,
    ordering: '-newsdate',
    pageSize: 10,
    year: null,
    month: null,
    day: null,

    /* eslint-disable */
    sortOptions: [{
      label: 'Newest to Oldest',
      value: '-newsdate'
    }, {
      label: 'Oldest to Newest',
      value: 'newsdate'
    }],

    pageOptions: [10, 25, 50, 100, 500],
    /* eslint-enable */

    years: Ember.computed('model.meta.dates', function () {
      return Object.keys(this.get('model.meta.dates')).reverse();
    }),
    showYears: Ember.computed('year', 'month', 'day', function () {
      var _getProperties = this.getProperties('year', 'month', 'day'),
          year = _getProperties.year,
          month = _getProperties.month,
          day = _getProperties.day;

      return !year && !month && !day;
    }),

    months: Ember.computed('year', 'model.meta.dates', function () {
      return Object.keys(this.get('model.meta.dates')[this.get('year')]);
    }),
    showMonths: Ember.computed('year', 'month', 'day', function () {
      var _getProperties2 = this.getProperties('year', 'month', 'day'),
          year = _getProperties2.year,
          month = _getProperties2.month,
          day = _getProperties2.day;

      return year && !month && !day;
    }),

    days: Ember.computed('year', 'month', 'model.meta.dates', function () {
      var _getProperties3 = this.getProperties('year', 'month'),
          year = _getProperties3.year,
          month = _getProperties3.month;

      return this.get('model.meta.dates')[year][month].map(Number).sort(function (a, b) {
        return a - b;
      });
    }),
    showDays: Ember.computed('year', 'month', 'day', function () {
      var _getProperties4 = this.getProperties('year', 'month', 'day'),
          year = _getProperties4.year,
          month = _getProperties4.month,
          day = _getProperties4.day;

      return year && month && !day;
    }),

    actions: {
      updateOrder: function updateOrder(e) {
        this.set('ordering', e.target.value);
      },
      updatePageSize: function updatePageSize(e) {
        this.set('pageSize', e.target.value);
      }
    }
  });
});