define('new-sounds-web-client/models/person', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    jobTitle: (0, _attr.default)('number'),
    image: (0, _attr.default)('object'),
    bio: (0, _attr.default)('string'),
    lede: (0, _attr.default)('string'),
    social: (0, _attr.default)('object'),
    shows: (0, _attr.default)('object')
  });
});