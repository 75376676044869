define('new-sounds-web-client/components/custom-color-rules/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'style',
    attributeBindings: ['type'],
    type: 'text/css',
    customColorStore: Ember.inject.service(),
    colors: Ember.computed.alias('customColorStore.colors')
  });
});