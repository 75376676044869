define('new-sounds-web-client/components/stream-banner/details', ['exports', 'nypr-audio-services/components/stream-banner/details'], function (exports, _details) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _details.default;
    }
  });
});