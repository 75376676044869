define("new-sounds-web-client/adapters/person", ["exports", "new-sounds-web-client/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return "person"; // well this is a shame, ember even knows this should be called people
    }
  });
});