define('new-sounds-web-client/helpers/hide-zero-minutes', ['exports', 'nypr-publisher-lib/helpers/hide-zero-minutes'], function (exports, _hideZeroMinutes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hideZeroMinutes.default;
    }
  });
  Object.defineProperty(exports, 'hideZeroMinutes', {
    enumerable: true,
    get: function () {
      return _hideZeroMinutes.hideZeroMinutes;
    }
  });
});