define('new-sounds-web-client/templates/components/freestyle-usage-controls', ['exports', 'ember-freestyle/templates/components/freestyle-usage-controls'], function (exports, _freestyleUsageControls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _freestyleUsageControls.default;
    }
  });
});