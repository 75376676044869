define('new-sounds-web-client/helpers/massage-cms-html', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.massageCmsHtml = massageCmsHtml;


  function wrapInParagraphIfNotAlready(div) {
    var ps = div.querySelector('p');
    if (!ps) {
      var p = document.createElement('p');
      p.innerHTML = div.innerHTML;
      div.innerHTML = p.outerHTML;
    }

    return div;
  }

  function alignIframes(content) {
    var iframes = content.querySelectorAll('iframe');
    Ember.$.each(iframes, function (index, iframe) {
      if (Ember.$(iframe).parents('.iframe-wrapper').length === 0) {
        var tag = Ember.$(iframe).parent()[0];
        Ember.$(tag).addClass('iframe-wrapper');
      }
    });

    return content;
  }

  function massageCmsHtml(params /*, hash*/) {
    var div = document.createElement('div');
    div.innerHTML = params[0];

    var output = wrapInParagraphIfNotAlready(div);
    output = alignIframes(output);

    return output.innerHTML;
  }

  exports.default = Ember.Helper.helper(massageCmsHtml);
});