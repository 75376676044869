define('new-sounds-web-client/helpers/progressive-image-loader', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.progressiveImageLoader = progressiveImageLoader;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var PATTERN = /\/(\d+)\/(\d+)\//;

  function precisionRound(number, precision) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  function calculateBiggestDimensions(desiredAspectRatio, containerWidth, containerHeight) {
    var maximizedToHeight = [containerWidth, Math.ceil(containerWidth / desiredAspectRatio)];
    var maximizedToWidth = [Math.ceil(containerHeight * desiredAspectRatio), containerHeight];

    if (maximizedToWidth[0] <= containerWidth) {
      return maximizedToWidth;
    } else if (maximizedToHeight[1] <= containerHeight) {
      return maximizedToHeight;
    }
  }

  function isValidImageServerUrl(imageUrl) {
    if (imageUrl && imageUrl.match(PATTERN)) {
      var _imageUrl$match = imageUrl.match(PATTERN),
          _imageUrl$match2 = _slicedToArray(_imageUrl$match, 3),
          width = _imageUrl$match2[1],
          height = _imageUrl$match2[2];

      if (width > 0 && height > 0) {
        return true;
      }
    }
    return false;
  }

  function notifyStaffAboutPoorQualityImage(imageUrl, actualDimensions, newImageRequest) {
    var _actualDimensions = _slicedToArray(actualDimensions, 2),
        actualWidth = _actualDimensions[0],
        actualHeight = _actualDimensions[1];

    if (_environment.default.environment == 'development') {
      console.log(imageUrl + ' returned with insufficient dimensions of ' + actualWidth + ' x ' + actualHeight);
      console.log('-> requesting ' + newImageRequest);
    }
  }

  function progressiveImageLoader(imageUrl) /*, hash*/{
    if (!isValidImageServerUrl(imageUrl)) {
      return Ember.RSVP.Promise.resolve(imageUrl);
    }

    return new Ember.RSVP.Promise(function (resolve) {
      var _imageUrl$match3 = imageUrl.match(PATTERN),
          _imageUrl$match4 = _slicedToArray(_imageUrl$match3, 3),
          width = _imageUrl$match4[1],
          height = _imageUrl$match4[2];

      var desiredRatio = (width || 1) / (height || 1);
      var secondRequest = false;

      var image = new Image();
      image.src = imageUrl;

      if (image.complete) {
        // Image was already loaded, resolve early
        resolve(image.src);
      }

      image.onload = function () {
        var actualRatio = image.naturalWidth / image.naturalHeight;
        if (precisionRound(actualRatio, 1) === precisionRound(desiredRatio, 1)) {
          /* The image server returned an appropriately sized image as requested */
          resolve(image.src);
        } else if (!secondRequest) {
          /* It didn't provide an image in the ratio we wanted, meaning that it provided the biggest image it
            had but in the wrong aspect ratio. Calculate the biggest image it can provide in the correct aspect
            ratio to maintain the layout.
          */

          // request a smaller file at the correct ratio.
          var newImageUrl = image.src.replace(PATTERN, function () {
            var _calculateBiggestDime = calculateBiggestDimensions(desiredRatio, image.naturalWidth, image.naturalHeight),
                _calculateBiggestDime2 = _slicedToArray(_calculateBiggestDime, 2),
                maxWidth = _calculateBiggestDime2[0],
                maxHeight = _calculateBiggestDime2[1];

            return '/' + maxWidth + '/' + maxHeight + '/';
          });

          notifyStaffAboutPoorQualityImage(imageUrl, [image.naturalWidth, image.naturalHeight], newImageUrl);

          image.src = newImageUrl;
          secondRequest = true;
        } else {
          resolve(image.src);
        }
      };
    });
  }

  exports.default = Ember.Helper.helper(progressiveImageLoader);
});