define('new-sounds-web-client/components/page-numbers/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['page-numbers'],
    classNameBindings: ['isLoading'],

    hasPages: Ember.computed.gt('totalPages', 1),
    hasOnePage: Ember.computed.equal('totalPages', 1),
    isLoading: false,
    pagesToShow: 10,

    bounds: Ember.computed('centerPage', 'pagesToShow', 'totalPages', function () {
      var pagesToShow = Ember.get(this, 'pagesToShow');
      var totalPages = Ember.get(this, 'totalPages');
      var range = Math.floor(pagesToShow / 2);

      if (totalPages < pagesToShow) {
        return {
          lower: 1,
          upper: totalPages,
          range: range
        };
      } else {
        var centerPage = Ember.get(this, 'centerPage');
        var pagesToShowIsEven = pagesToShow % 2 === 0;

        return {
          lower: centerPage - (pagesToShowIsEven ? range - 1 : range),
          upper: centerPage + range > totalPages ? totalPages : centerPage + range,
          range: range
        };
      }
    }),

    centerPage: Ember.computed('currentPage', 'pageToShow', function () {
      var currentPage = Ember.get(this, 'currentPage');
      var pagesToShow = Ember.get(this, 'pagesToShow');
      Ember.set(this, 'loadingPage', null); //eslint-disable-line ember/no-side-effects

      var minCenterPage = Math.ceil(pagesToShow / 2);
      return currentPage >= minCenterPage ? currentPage : minCenterPage;
    }),

    onFirstPage: Ember.computed.equal('currentPage', 1),

    onLastPage: Ember.computed('currentPage', 'totalPages', 'hasOnePage', function () {
      return Ember.get(this, 'currentPage') === Ember.get(this, 'totalPages') || Ember.get(this, 'hasOnePage');
    }),

    pages: Ember.computed('bounds', 'totalPages', 'pagesToShow', 'centerPage', 'loadingPage', 'isLoading', function () {
      var bounds = Ember.get(this, 'bounds');
      var totalPages = Ember.get(this, 'totalPages');
      var currentPage = Ember.get(this, 'currentPage');
      var loadingPage = Ember.get(this, 'loadingPage');

      var pages = [];
      for (var i = bounds.lower; i <= bounds.upper; i++) {
        pages.push({
          page: i,
          loading: i === loadingPage,
          current: i === currentPage
        });
      }

      // only add dots if the lower boundary is more than
      // on away from the first page, i.e. is it greater than 2
      if (bounds.lower > 2) {
        pages[0].dots = true;
      }

      if (bounds.lower !== 1) {
        pages.unshift({
          page: 1
        });
      }

      if (bounds.upper !== totalPages) {
        pages.push({
          page: totalPages,
          // only add dots if the upper boundary is more
          // than one away from the totalPages
          dots: totalPages - bounds.upper > 1
        });
      }
      return pages;
    }),

    actions: {
      pageClicked: function pageClicked(num) {
        Ember.set(this, 'loadingPage', num);
        this.get('action')(num);
      },
      incrementPage: function incrementPage(num) {
        var currentPage = Ember.get(this, 'currentPage');
        var totalPages = Ember.get(this, 'totalPages');

        if (currentPage === totalPages && num === 1) {
          return false;
        }
        if (currentPage <= 1 && num === -1) {
          return false;
        }
        var newPage = parseInt(this.get('currentPage'), 10) + num;
        Ember.set(this, 'loadingPage', newPage);
        this.get('action')(newPage);
      }
    }
  });
});