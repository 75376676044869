define('new-sounds-web-client/controllers/freestyle', ['exports', 'ember-freestyle/controllers/freestyle'], function (exports, _freestyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _freestyle.default.extend({
    emberFreestyle: Ember.inject.service(),
    appController: Ember.inject.controller('application'),

    init: function init() {
      this.get('appController').set('chromeDisabled', true);
      this._super.apply(this, arguments);
    },

    /* eslint-disable */

    colorPalette: [{
      name: "$logo-color__default",
      base: "#ff9efc"
    }, {
      name: "$play-button-color__default",
      base: "#f25ced"
    }, {
      name: "$text-color__default",
      base: "#cc4ec8"
    }, {
      name: "$horizontal-rule-color",
      base: "#d9d9d9"
    }, {
      name: "$date-time-color",
      base: "#adadad"
    }, {
      name: "$body-color",
      base: "#000000"
    }, {
      name: "$link-color",
      base: "#189fc4"
    }, {
      name: "$link-color__hover",
      base: "#106d85"
    }, {
      name: "$global-player-color",
      base: "#451a43"
    }],
    colorTintDynamicProperties: {
      color: {
        value: "#f25ced"
      }
    },
    showGridDynamicProperties: {
      title: {
        value: 'Dynamic Title'
      },
      tease: {
        value: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      },
      routeName: {
        value: null
      },
      hasPhoto: {
        value: true,
        inputType: 'checkbox'
      },
      color: {
        value: null
      }
    },
    progressiveImageDynamicProperties: {
      imageUrl: {
        value: 'https://media.demo.nypr.digital/i/560/315/l/99/1/high_plains_bandcamp.jpg'
      }
    },
    episodeGridDynamicProperties: {
      title: {
        value: 'Title'
      },
      showTitle: {
        value: "New Sounds"
      },
      show: {
        value: 'newsounds'
      },
      image: {
        value: "http://via.placeholder.com/500x500/?text=Episode+Photo"
      },
      date: {
        value: "2017-11-03"
      },
      audio: {
        value: true,
        inputType: 'checkbox'
      },
      time: {
        value: "5 minutes"
      },
      itemPK: {
        value: null
      },
      color: {
        value: null
      }
    },
    personGridDynamicProperties: {
      title: {
        value: 'Job Title'
      },
      name: {
        value: "Person Name"
      },
      slug: {
        value: "johnny"
      },
      image: {
        value: {
          template: "http://via.placeholder.com/300x200/?text=Person+Photo"
        }
      },
      bio: {
        value: "Hey look at this neat bio"
      }
      /* eslint-enable */
    } });
});