define('new-sounds-web-client/services/data-pipeline', ['exports', 'nypr-metrics/services/data-pipeline'], function (exports, _dataPipeline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dataPipeline.default.extend({
    session: Ember.inject.service(),
    browserId: Ember.computed.readOnly('session.data.browserId')
  });
});