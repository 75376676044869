define('new-sounds-web-client/models/stream', ['exports', 'nypr-publisher-lib/models/stream', 'new-sounds-web-client/config/environment'], function (exports, _stream, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stream.default.extend({
    shareMetadata: Ember.computed('currentShow', 'currentPlaylistItem', function () {
      var shareText = '';
      var shareUrl = '';
      var via = Ember.get(this, 'twitterHandle') || 'newsounds';

      var entry = Ember.get(this, 'currentPlaylistItem.catalogEntry');
      if (entry && Ember.get(entry, 'composer.name') && Ember.get(entry, 'title')) {
        shareText = 'I\'m listening to ' + Ember.get(entry, 'composer.name') + ' - ' + Ember.get(entry, 'title');
        shareUrl = _environment.default.webRoot + '/livestream/';
      } else {
        shareText = 'I\'m listening to ' + Ember.get(this, 'currentShow.title');
        shareUrl = Ember.get(this, 'currentShow.url');
      }

      return { shareText: shareText, shareUrl: shareUrl, via: via };
    })
  });
});