define('new-sounds-web-client/utils/alien-dom', ['exports', 'nypr-django-for-ember/utils/alien-dom'], function (exports, _alienDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'isInDom', {
    enumerable: true,
    get: function () {
      return _alienDom.isInDom;
    }
  });
  Object.defineProperty(exports, 'clearAlienDom', {
    enumerable: true,
    get: function () {
      return _alienDom.clearAlienDom;
    }
  });
  Object.defineProperty(exports, 'unbindAlienListener', {
    enumerable: true,
    get: function () {
      return _alienDom.unbindAlienListener;
    }
  });
  Object.defineProperty(exports, 'embeddedComponentSetup', {
    enumerable: true,
    get: function () {
      return _alienDom.embeddedComponentSetup;
    }
  });
  Object.defineProperty(exports, 'installAlienListeners', {
    enumerable: true,
    get: function () {
      return _alienDom.installAlienListeners;
    }
  });
  Object.defineProperty(exports, 'addAlienLanding', {
    enumerable: true,
    get: function () {
      return _alienDom.addAlienLanding;
    }
  });
  Object.defineProperty(exports, 'assign', {
    enumerable: true,
    get: function () {
      return _alienDom.assign;
    }
  });
});