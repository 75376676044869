define('new-sounds-web-client/instance-initializers/google-tag-manager', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    if (typeof window !== 'undefined' && _environment.default.environment !== 'test') {
      // eslint-disable
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', _environment.default.googleTagManager);
      // eslint-enable
    }
  }

  exports.default = {
    initialize: initialize
  };
});