define('new-sounds-web-client/instance-initializers/eager-load-services', ['exports', 'nypr-audio-services/instance-initializers/eager-load-services'], function (exports, _eagerLoadServices) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eagerLoadServices.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _eagerLoadServices.initialize;
    }
  });
});