define('new-sounds-web-client/components/player-notification/autoplay-message', ['exports', 'nypr-audio-services/components/player-notification/autoplay-message', 'new-sounds-web-client/templates/components/player-notification/autoplay-message'], function (exports, _autoplayMessage, _autoplayMessage2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _autoplayMessage.default.extend({
    layout: _autoplayMessage2.default,
    notificationMessagePreSwitch: Ember.computed('streamEnabled', 'preferredStream.name', 'remaining', function () {
      var remaining = Ember.get(this, 'remaining');
      return 'Your episode is over. In ' + remaining + ' seconds, we\'ll tune you to the New Sounds live stream.';
    }),

    notificationMessagePostSwitch: Ember.computed('streamEnabled', 'preferredStream.name', function () {
      return 'We tuned you to the New Sounds live stream after your episode ended.';
    })
  });
});