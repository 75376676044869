define('new-sounds-web-client/helpers/get-bucket', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getBucket = getBucket;
  function getBucket(store, bucketName) {

    return new Ember.RSVP.Promise(function (resolve) {
      store.findRecord('bucket', bucketName).then(function (b) {
        var bucketItems = Ember.get(b, 'bucketItems');
        if (bucketItems) {
          resolve({ bucketItems: bucketItems, editLink: Ember.get(b, 'editLink') });
        } else {
          resolve({ bucketItems: [] });
        }
      }).catch(function () {
        resolve({ bucketItems: [] });
        // what it should do is 404 if there is no response, but
        // currently it just gives some empty attributes
      });
    });
  }

  exports.default = getBucket;
});