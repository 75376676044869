define('new-sounds-web-client/utils/mime-types', ['exports', 'ember-hifi/utils/mime-types'], function (exports, _mimeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'getMimeType', {
    enumerable: true,
    get: function () {
      return _mimeTypes.getMimeType;
    }
  });
});