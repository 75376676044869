define('new-sounds-web-client/services/custom-color-store', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    init: function init() {
      this.set('defaultColors', {
        "livestream": "f25ced"
      });

      this.set('customColors', {});
      this._super.apply(this, arguments);
    },


    colors: Ember.computed('defaultColors', 'customColors', function () {
      return Ember.merge(Ember.get(this, 'defaultColors'), Ember.get(this, 'customColors'));
    }),
    update: function update() {
      // Finds the background colors from the show objects in the store.
      // The custom-color-rules component updates when this value updates
      var colors = Ember.get(this, 'customColors');

      var featuredBucket = Ember.get(this, 'store').peekRecord('bucket', 'newsounds-featured-shows');

      if (featuredBucket) {
        featuredBucket.get('bucketItems').forEach(function (b) {
          colors[Ember.get(b, 'attributes.slug')] = Ember.get(b, 'attributes.bgColor');
        });
      }

      var shows = Ember.get(this, 'store').peekAll('show');

      if (shows) {
        shows.toArray().forEach(function (show) {
          colors[Ember.get(show, 'slug')] = Ember.get(show, 'bgColor');
        });
      }

      Ember.set(this, 'customColors', colors);
      this.notifyPropertyChange('colors');
    }
  });
});