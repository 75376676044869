define('new-sounds-web-client/components/custom-color-rules/ruleset/component', ['exports', 'new-sounds-web-client/mixins/color-generator'], function (exports, _colorGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_colorGenerator.default, {
    tagName: '',
    customSelector: Ember.computed('key', function () {
      return '[data-custom-color="' + this.get('key') + '"]';
    }),
    outputRules: Ember.computed.and('key', 'defaultColor')
  });
});