define('new-sounds-web-client/mixins/color-generator', ['exports', 'new-sounds-web-client/helpers/color-changer'], function (exports, _colorChanger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    defaultColor: Ember.computed('color', function () {
      var color = Ember.get(this, 'color');
      if (color) {
        return _colorChanger.default.compute([color, 0]);
      }
    }),
    darkColor: Ember.computed('defaultColor', function () {
      var defaultColor = Ember.get(this, 'defaultColor');
      if (defaultColor) {
        return _colorChanger.default.compute([defaultColor, -20]);
      }
    }),
    lightColor: Ember.computed('defaultColor', function () {
      var defaultColor = Ember.get(this, 'defaultColor');
      if (defaultColor) {
        return _colorChanger.default.compute([defaultColor, 20]);
      }
    })
  });
});