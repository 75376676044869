define('new-sounds-web-client/helpers/social-links-from-person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.socialLinksFromPerson = socialLinksFromPerson;


  function socialUrl(service, user) {
    switch (service) {
      case 'facebook':
        return 'http://facebook.com/' + user;
      case 'instagram':
        return 'http://instagram.com/' + user;
      case 'twitter':
        return 'http://twitter.com/' + user;
      case 'email':
        return 'mailto:' + user;
      default:
        return null;
    }
  }

  function socialLinksFromPerson(params /*, hash*/) {
    var results = [];
    params[0].forEach(function (info) {
      if (info['service'] && info['contactString']) {
        results.push({
          service: info['service'],
          url: socialUrl(info['service'], info['contactString'])
        });
      }
    });

    return results;
  }

  exports.default = Ember.Helper.helper(socialLinksFromPerson);
});