define('new-sounds-web-client/helpers/story-appearance-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.storyAppearanceList = storyAppearanceList;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function storyAppearanceList(params /*, hash*/) {

    var story = params[0];
    var show = params[1];

    var appearances = Ember.get(story, 'appearances');
    var hostsAndAuthors = [].concat(_toConsumableArray(appearances.hosts), _toConsumableArray(appearances.authors));
    var people = [];
    if (hostsAndAuthors.length > 0) {
      people = hostsAndAuthors;
    } else {
      people = Ember.makeArray(Ember.get(show, 'about.people')).slice(0, 1);
    }

    return people.map(function (p) {
      return {
        name: Ember.get(p, 'name'),
        jobTitle: Ember.get(p, 'jobTitle'),
        image: Ember.get(p, 'image'),
        slug: Ember.get(p, 'slug')
      };
    });
  }

  exports.default = Ember.Helper.helper(storyAppearanceList);
});