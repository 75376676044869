define('new-sounds-web-client/routes/person-detail', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return [model.person.get('name'), "People"];
    },

    model: function model(_ref) {
      var _this = this;

      var slug = _ref.slug;

      return Ember.RSVP.hash({
        person: this.store.findRecord("person", slug)
      }).catch(function (e) {
        if (e instanceof _emberData.default.NotFoundError) {
          _this.transitionTo('not-found', 'people/' + slug);
        } else {
          throw e;
        }
      });
    }
  });
});