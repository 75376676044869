define('new-sounds-web-client/components/episode-grid-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['colorKey:data-custom-color'],
    classNames: ['episode-grid-item']
  });
});