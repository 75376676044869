define('new-sounds-web-client/helpers/fix-timestamp', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fixTimestamp;
  function fixTimestamp(timestamp) {

    if ((0, _moment.default)(timestamp).tz() === undefined) {
      return _moment.default.tz(timestamp, "America/New_York").toISOString();
    } else {
      return (0, _moment.default)(timestamp).toISOString();
    }
  }
});