define('new-sounds-web-client/routes/application', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    session: Ember.inject.service(),
    customColorStore: Ember.inject.service(),
    poll: Ember.inject.service(),
    playlist: Ember.inject.service(),
    dj: Ember.inject.service(),

    title: function title() {
      var _this = this;

      var tokens = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var siteName = 'New Sounds';
      var tagline = 'Hand-picked music, genre free';

      // combine the first two items if the second items starts with `:`
      if (tokens[1] && tokens[1].startsWith(':')) {
        tokens.splice(0, 2, '' + tokens[0] + tokens[1]);
      }

      if (tokens.length <= 1) {
        tokens.push(siteName);
        tokens.push(tagline);
      } else if (tokens.length === 2) {
        tokens.push(siteName);
      }

      var title = tokens.join(' | ');
      Ember.get(this, 'dataLayer').setPageTitle(title);

      Ember.run.schedule('afterRender', function () {
        Ember.get(_this, 'dataLayer').sendPageView();
      });
      return title;
    },
    setupController: function setupController() {
      var _this2 = this;

      Ember.get(this, 'playlist').refreshStream();
      Ember.get(this, 'session').staffAuth();
      Ember.get(this, 'session').syncBrowserId().then(function (id) {
        return Ember.get(_this2, 'dj').addBrowserId(id);
      });
    },


    actions: {
      error: function error(_error) {
        console.error(_error);
        return this.replaceWith('server-errors.internal-error');
      },
      loading: function loading(transition) {
        var _this3 = this;

        var controller = this.controllerFor('application');

        window.$('html').animate({ scrollTop: 0 }, 500);

        Ember.run.schedule('actions', function () {
          controller.set('currentlyLoading', true);

          transition.promise.finally(function () {
            controller.set('currentlyLoading', false);
            _this3.controllerFor('application').set('expandedHeader', transition.targetName === 'index');
          });
        });

        return true; // allows the loading template to be shown
      },
      fetchAllShows: function fetchAllShows() {
        var _this4 = this;

        var allShows = Ember.RSVP.hash({
          liveStream: this.store.findRecord('stream', 'q2'),
          featuredItems: (0, _getBucket.default)(this.store, 'newsounds-featured-shows').then(function (b) {
            return Ember.get(b, 'bucketItems');
          })
        });

        allShows.then(function () {
          _this4.get('customColorStore').update();
        });

        return allShows;
      }
    }
  });
});