define('new-sounds-web-client/routes/playlist', ['exports', 'new-sounds-web-client/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "24/7 Live Stream",
    playlist: Ember.inject.service(),
    queryParams: {
      date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var date = (0, _moment.default)().format();
      if (params.date != null) {
        date = (0, _moment.default)(params.date, 'YYYY/MMM/DD').format();
      }
      var playlist = this.get('playlist');
      return playlist.load(date).then(function () {
        return {
          playlistUrl: '' + _environment.default.playlistURL
        };
      });
    },
    deactivate: function deactivate() {
      this.get('playlist').set('updatePlaylist', false);
      this._super.apply(this, arguments);
    },
    activate: function activate() {
      this.get('playlist').set('updatePlaylist', true);
      this._super.apply(this, arguments);
    }
  });
});