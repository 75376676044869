define('new-sounds-web-client/hotjar/main', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var hj = window.hj = window.hj || function () {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

  function load(id, forceSSL, snippetVer) {

    setTimeout(function () {
      window._hjSettings = {
        hjid: id,
        hjsv: snippetVer ? snippetVer : 5
      };

      var prefix = '//';
      if (forceSSL) {
        prefix = 'https://';
      }

      var a = document.getElementsByTagName('head')[0];
      var r = document.createElement('script');
      r.async = 1;
      r.src = prefix + 'static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
      a.appendChild(r);
    }, 1);
  }

  if (_environment.default.hotjar && _environment.default.hotjar.id) {

    if ('enabled' in _environment.default.hotjar && !_environment.default.hotjar.enabled) {
      Ember.Logger.debug('Not running hotjar script, config.hotjar.enabled set to false');
    } else {
      load(_environment.default.hotjar.id, !!_environment.default.hotjar.forceSSL, _environment.default.hotjar.snippetVersion);
    }
  } else {
    throw new TypeError('Missing config/environment entry `config.hotjar.id`');
  }

  exports.default = {
    create: function create() {
      return hj;
    }
  };
});