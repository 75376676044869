define('new-sounds-web-client/models/track', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr'], function (exports, _emberData, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    playlist: Ember.inject.service(),
    show: (0, _relationships.belongsTo)({ async: false }),
    airing: (0, _relationships.belongsTo)({ async: false }),

    buyLink: (0, _attr.default)(),
    startTime: (0, _attr.default)('string'),
    startTimeTs: (0, _attr.default)('number'),
    groupingKey: (0, _attr.default)('string'),
    catalogEntry: (0, _attr.default)(),
    playlistEntryId: (0, _attr.default)('number'),

    artist: Ember.computed.alias('catalogEntry.composer.name'),
    trackLength: Ember.computed.alias('catalogEntry.length'),
    title: Ember.computed.alias('catalogEntry.title'),
    soloists: Ember.computed('catalogEntry', function () {
      var soloists = "";
      Ember.getWithDefault(this, 'catalogEntry.soloists', []).forEach(function (soloist) {
        soloists += ', ' + soloist.musician.name;
        if (soloist.instruments.length > 0) {
          soloists += ' (' + soloist.instruments[0] + ')';
        }
      });

      return soloists;
    }),
    isLive: Ember.computed('playlist.nowPlayingId', 'playlistEntryId', function () {
      return Ember.get(this, 'playlist.nowPlayingId') == Ember.get(this, 'playlistEntryId');
    }),

    humanTrackLength: Ember.computed('trackLength', function () {
      var length = Ember.get(this, 'trackLength');

      var hrs = ~~(length / 3600);
      var mins = ~~(length % 3600 / 60);
      var secs = length % 60;

      var parts = [];

      if (hrs > 0) parts.push(hrs + 'h');
      if (mins > 0) parts.push(mins + 'm');
      if (secs > 0) parts.push(secs + 's');

      return parts.join(" ");
    })
  });
});