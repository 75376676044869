define('new-sounds-web-client/routes/news', ['exports', 'new-sounds-web-client/mixins/fetch-news-action', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _fetchNewsAction, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fetchNewsAction.default, {
    titleToken: "Features",
    model: function model() {
      var _this = this;

      var fetch = Ember.run.bind(this, this.actions.fetchNews);
      return fetch({ pageSize: 12, page: 1 }).then(function (results) {
        var articles = results.toArray();

        return Ember.RSVP.hash({
          featuredItem: articles[0],
          articles: results, /* we're including the entire query result so the data-loader component has the meta info for paging */
          gigAlerts: _this.store.query("story", {
            show: 'gig-alerts',
            item_type: "episode",
            ordering: "-newsdate",
            audio_only: 1,
            page_size: 4
          }),
          dailyVideo: (0, _getBucket.default)(_this.store, "newsounds-daily-video").then(function (b) {
            return Ember.get(b, "bucketItems.firstObject");
          })
        });
      });
    }
  });
});