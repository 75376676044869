define('new-sounds-web-client/components/progressive-image/component', ['exports', 'new-sounds-web-client/helpers/progressive-image-loading-url', 'new-sounds-web-client/helpers/progressive-image-loader'], function (exports, _progressiveImageLoadingUrl, _progressiveImageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['progressive-image'],
    classNameBindings: ['isLoaded'],

    width: Ember.computed('imageUrl', function () {
      if (Ember.get(this, 'imageUrl')) {
        var matches = this.get('imageUrl').match(/\/(\d+)\/(\d+)\//);
        if (matches) {
          return matches[1];
        }
      }
    }),

    height: Ember.computed('imageUrl', function () {
      if (Ember.get(this, 'imageUrl')) {
        var matches = this.get('imageUrl').match(/\/(\d+)\/(\d+)\//);
        if (matches) {
          return matches[2];
        }
      }
    }),

    pictureStyle: Ember.computed('imageUrl', 'width', 'height', function () {
      if (Ember.get(this, 'imageUrl')) {
        return Ember.String.htmlSafe('padding-bottom: ' + (this.get('height') || 1) / (this.get('width') || 1) * 100 + '%');
      }
    }),

    placeholderImageUrl: Ember.computed('imageUrl', function () {
      // Take the NYPR image url which is something like this:
      // https://media.demo.nypr.digital/i/560/315/l/99/1/high_plains_bandcamp.jpg"
      // and request a tiny version by dividing the /:width/:height/ parameters by 20

      if (Ember.get(this, 'imageUrl')) {
        return (0, _progressiveImageLoadingUrl.progressiveImageLoadingUrl)(this.get('imageUrl'));
      }
    }),

    lazyLoadImage: function lazyLoadImage() {
      var _this = this;

      /* lazy load full image and show blurred low quality loading image until it's loaded
         then fade from blurry image to full size render using css transition */
      return (0, _progressiveImageLoader.progressiveImageLoader)(this.get('imageUrl')).then(function (url) {
        if (!_this.isDestroyed) {
          // if Image was already loaded (cached) this promise resolved immediately and
          // the is-loaded class won't cause a CSS transition since it will arrive in the DOM with the class

          _this.set('actualImageUrl', url);
          _this.set('isLoaded', true);
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('isLoaded', false);
      this.lazyLoadImage();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('isLoaded')) {
        this.lazyLoadImage();
      }
    }
  });
});