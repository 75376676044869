define('new-sounds-web-client/components/big-play-button/component', ['exports', 'nypr-audio-services/components/listen-button', 'nypr-audio-services/templates/components/listen-button', 'new-sounds-web-client/helpers/progressive-image-loading-url', 'new-sounds-web-client/helpers/progressive-image-loader'], function (exports, _listenButton, _listenButton2, _progressiveImageLoadingUrl, _progressiveImageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listenButton.default.extend({
    classNames: ['new-sounds-listen-button mod-big mod-round'],
    classNameBindings: ['isLoaded'],

    loadingImageBackground: Ember.computed('image', function () {
      var loadingImageUrl = (0, _progressiveImageLoadingUrl.progressiveImageLoadingUrl)(this.get('image'));
      return Ember.String.htmlSafe('background-image: url(' + loadingImageUrl + ')');
    }),

    style: Ember.computed('fullSizeImage', 'isLoaded', function () {
      if (!this.get('isLoaded')) return;
      var url = this.get('fullSizeImage');
      return Ember.String.htmlSafe('background-image: url(' + url + ');');
    }),

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      /* lazy load full image and show blurred low quality loading image until it's loaded */

      (0, _progressiveImageLoader.progressiveImageLoader)(this.get('image')).then(function (url) {
        _this.set('fullSizeImage', url);
        _this.set('isLoaded', true);
      });
    },
    layout: _listenButton2.default
  });
});