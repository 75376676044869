define('new-sounds-web-client/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    redirect: function redirect(_ref) {
      var url = _ref.url;

      if (url.split('/')[0] === 'news') {
        this.transitionTo('news');
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        return _this.get('dataLayer').send404();
      });
    }
  });
});