define('new-sounds-web-client/routes/search', ['exports', 'nypr-django-for-ember/utils/compat-hooks'], function (exports, _compatHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    },
    model: function model() {
      return this.store.find('django-page', 'search/');
    },
    afterModel: function afterModel() {
      Ember.run.schedule('afterRender', function () {
        // Search won't render again if coming back to the page
        var element = window.$('#cse-search-results');
        if (element.length > 0) {
          element.wnyc('searchInit');
        } else {
          Ember.run.later(function () {
            // if Search hasn't rendered yet, which happens sometimes but not always,
            // try to render again in 500 ms (after the current run loop)
            var renderSearch = function renderSearch() {
              var element = window.$('#cse-search-results');
              var element2 = window.$('#___gcse_0');
              if (element2.length === 0) {
                if (element.length > 0) {
                  element.wnyc('searchInit');
                }
                // if it still hasn't rendered keep trying
                Ember.run.later(renderSearch, 500);
              }
            };
            renderSearch();
          }, 500);
        }
      });
    },


    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        (0, _compatHooks.beforeTeardown)();
        return true;
      }
    }
  });
});