define('new-sounds-web-client/controllers/playlist', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    playlist: Ember.inject.service(),
    queryParams: ['date'],
    date: null
  });
});