define('new-sounds-web-client/helpers/image-srcset', ['exports', 'nypr-ui/helpers/image-srcset'], function (exports, _imageSrcset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _imageSrcset.default;
    }
  });
  Object.defineProperty(exports, 'imageSrcset', {
    enumerable: true,
    get: function () {
      return _imageSrcset.imageSrcset;
    }
  });
});