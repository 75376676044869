define('new-sounds-web-client/utils/is-js', ['exports', 'nypr-django-for-ember/utils/is-js'], function (exports, _isJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isJs.default;
    }
  });
});