define('new-sounds-web-client/services/playlist', ['exports', 'ember-get-config', 'fetch', 'moment', 'new-sounds-web-client/helpers/fix-timestamp'], function (exports, _emberGetConfig, _fetch, _moment, _fixTimestamp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    host: _emberGetConfig.default.publisherAPI,
    store: Ember.inject.service(),
    hifi: Ember.inject.service(),
    poll: Ember.inject.service(),
    pollForUpdates: Ember.computed('updatePlaylist', 'playingStream', function () {
      if (Ember.get(this, 'updatePlaylist') || Ember.get(this, 'playingStream')) {
        this.get('poll').startPoll(Ember.get(this, 'pollId'));
        // run.next(() => this.refreshStream());
        return true;
      } else {
        this.get('poll').stopPoll(Ember.get(this, 'pollId'));
        return false;
      }
    }),
    updatePlaylist: false,
    playingStream: Ember.computed.and('hifi.{currentSound.isStream,isPlaying}'),
    scheduleDate: (0, _moment.default)().format("YYYY/MMM/DD"), //currentScheduleId
    isTodaysSchedule: Ember.computed('scheduleDate', function () {
      return (0, _moment.default)().format('YYYY/MMM/DD') == Ember.get(this, 'scheduleDate');
    }),
    previousScheduleDate: Ember.computed('scheduleDate', function () {
      return (0, _moment.default)(Ember.get(this, 'scheduleDate'), 'YYYY/MMM/DD').subtract(1, 'day').format('YYYY/MMM/DD');
    }),
    nextScheduleDate: Ember.computed('scheduleDate', function () {
      return (0, _moment.default)(Ember.get(this, 'scheduleDate'), 'YYYY/MMM/DD').add(1, 'day').format('YYYY/MMM/DD');
    }),

    init: function init() {
      var _this = this;

      this.set('tracks', []);
      this.set('airings', []);
      var pollFunction = function pollFunction() {
        return _this.refreshStream();
      };
      var pollId = this.get('poll').addPoll({ interval: 30 * 1000, callback: pollFunction });
      this.set('pollId', pollId);

      this._super.apply(this, arguments);
    },
    load: function load(date) {
      var _this2 = this;

      this.set('scheduleDate', (0, _moment.default)(date).format('YYYY/MMM/DD'));
      var prefetchedSchedule = Ember.get(this, 'store').peekRecord('daily-schedule', (0, _moment.default)(date).format('YYYY/MMM/DD'));
      if (prefetchedSchedule) {
        this.set('dailySchedule', prefetchedSchedule);
        return this.refreshStream(true);
      } else {
        var requestUrl = this._scheduleUrlForDate(date);
        return this.getSchedule(requestUrl).then(function () {
          _this2.refreshStream(true);
        });
      }
    },
    refreshStream: function refreshStream() {
      var _this3 = this;

      var updatePlaylist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.get(this, 'updatePlaylist');

      var promise = Ember.get(this, 'store').findRecord('stream', 'q2');

      if (updatePlaylist) {
        promise.then(function (response) {
          return _this3.processWhatsOn(response);
        });
      }
      return promise;
    },
    processWhatsOn: function processWhatsOn(response) {
      Ember.set(this, 'nowPlayingId', "entry_" + Ember.get(response, 'currentPlaylistItem.playlistEntryId'));

      var newTrack = Ember.get(response, 'currentPlaylistItem');
      if (newTrack) {
        var store = Ember.get(this, 'store');
        var startTime = Ember.get(newTrack, 'startTime');
        var trackId = "entry_" + Ember.get(newTrack, 'playlistEntryId');
        if (trackId && !store.peekRecord('track', trackId)) {
          newTrack = store.createRecord('track', {
            id: trackId,
            playlistEntryId: trackId,
            catalogEntry: Ember.get(newTrack, 'catalogEntry'),
            startTime: (0, _moment.default)(startTime).format('hh:mm a')
          });
        } else {
          newTrack = null;
        }
      }

      var newShow = Ember.get(response, 'currentShow');
      if (newShow) {
        var airing = this.findAiring((0, _fixTimestamp.default)(Ember.get(newShow, 'start')));
        if (airing && newTrack) {
          var tracks = [newTrack];
          tracks.pushObjects(Ember.get(airing, 'tracks'));
          Ember.set(airing, 'tracks', tracks);
        }
        if (airing && Ember.get(newShow, 'episodePk')) {
          this.get('store').findRecord('story', Ember.get(newShow, 'episodePk')).then(function (story) {
            airing.set('episode', story);
          });
        }
        if (airing && !Ember.get(airing, 'isCurrent')) {
          Ember.set(airing, 'isCurrent', true);
        }
        // if airing not found, do nothing with track. most likely scenario is that
        // the clock hit midnight and the next day's schedule has not been loaded.
      }
    },
    getSchedule: function getSchedule(requestUrl) {
      var _this4 = this;

      Ember.debug('getting schedule ' + requestUrl);
      return (0, _fetch.default)(requestUrl).then(function (response) {
        return response.json().then(function (r) {
          Ember.debug('process schedule ' + requestUrl);
          return _this4.processSchedule(r);
        });
      });
    },
    processSchedule: function processSchedule(results) {
      var _this5 = this;

      var dailySchedule = Ember.get(this, 'store').createRecord('daily-schedule', { airings: [], id: this.get('scheduleDate') });
      return Ember.A(results.events).reverseObjects().map(function (event) {
        var attrs;
        var playlists = event.playlists.map(function (p) {
          return p.played.map(function (t) {
            return _this5.processTrack(t);
          });
        });
        if (Ember.get(event, 'isObject')) {
          var matches = event.show_url.match('shows/(.+)');
          var showSlug = matches ? matches[1] : "newsounds";

          attrs = {
            id: event.start_timestamp + '_' + showSlug,
            startTime: event.start_timestamp,
            endTime: event.end_timestamp,
            title: event.show_title,
            showId: event.show_id,
            showSlug: showSlug,
            tracks: [].concat.apply([], playlists).sort(function (a, b) {
              return (0, _moment.default)(Ember.get(a, 'startTime'), 'hh:mm a') > (0, _moment.default)(Ember.get(b, 'startTime'), 'hh:mm a') ? -1 : 1;
            })
          };
        } else {
          attrs = {
            id: event.start_timestamp + '_new-sounds-radio',
            startTime: event.start_timestamp,
            endTime: (0, _fixTimestamp.default)(event.end_timestamp),
            title: "New Sounds Radio",
            showSlug: 'new-sounds-radio',
            tracks: [].concat.apply([], playlists).sort(function (a, b) {
              return (0, _moment.default)(Ember.get(a, 'startTime')) > (0, _moment.default)(Ember.get(b, 'startTime'), 'hh:mm a') ? -1 : 1;
            })
          };
        }

        var airing = Ember.get(_this5, 'store').createRecord('airing', attrs);

        Ember.get(_this5, 'store').findRecord('show', attrs.showSlug).catch(function () {
          return Ember.get(_this5, 'store').findRecord('show', 'living-music-living-composers').then(function (show) {
            airing.set('show', show);
          });
        }).then(function (show) {
          airing.set('show', show);
        });

        Ember.set(_this5, 'dailySchedule', dailySchedule);
        dailySchedule.get('airings').addObject(airing);
      });
    },
    _scheduleUrlForDate: function _scheduleUrlForDate(rawDate) {
      var date = _moment.default.tz(rawDate, "America/New_York");
      var slug = 'q2';
      var year = date.format("YYYY");
      var month = date.format('MMM').toLowerCase();
      var day = date.format('DD');

      var url = this.host + '/v1/playlist-daily/' + slug + '/' + year + '/' + month + '/' + day + '/';
      return url;
    },
    processTrack: function processTrack(track) {
      var store = Ember.get(this, 'store');
      var trackId = Ember.get(track, 'id');
      if (trackId && !store.peekRecord('track', trackId)) {
        var record = store.createRecord('track', {
          id: Ember.get(track, 'id'),
          playlistEntryId: Ember.get(track, 'id'),
          catalogEntry: Ember.get(track, 'info'),
          startTime: Ember.get(track, 'time')
        });
        return record;
      } else if (trackId) {
        return store.peekRecord('track', trackId);
      } else {
        return null;
      }
    },
    findAiring: function findAiring(timestampWithZone) {
      var playTime = (0, _moment.default)(timestampWithZone);

      var airing = Ember.get(this, 'store').peekAll('airing').find(function (s) {
        return playTime.isBetween((0, _moment.default)(Ember.get(s, 'startTime')), (0, _moment.default)(Ember.get(s, 'endTime')), null, '[]');
      });

      if (!airing) {
        Ember.debug('could not find schedule item for track ' + timestampWithZone + ' -> ' + playTime);
      }

      return airing;
    }
  });
});