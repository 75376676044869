define('new-sounds-web-client/mirage-factories/api-response', ['exports', 'nypr-publisher-lib/mirage-factories/api-response'], function (exports, _apiResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _apiResponse.default;
    }
  });
});