define("new-sounds-web-client/components/site-chrome/listen-live/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['listen-live-toolbar'],
    currentlyPlaying: Ember.computed('dj.{isPlaying,currentContentId}', function () {
      return Ember.get(this, 'dj.isPlaying') && Ember.get(this, 'dj.currentContentId') === 'q2';
    }),
    dj: Ember.inject.service(),
    actions: {
      play: function play() {
        if (!this.get('currentlyPlaying')) {
          this.get('dj').play("q2", {});
        } else {
          this.get('dj').pause();
        }
      }
    }
  });
});