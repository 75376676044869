define('new-sounds-web-client/initializers/ember-freestyle', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function initialize() {
    var application = arguments[1] || arguments[0];
    var prefix = _environment.default.modulePrefix;
    var freestyleModuleRegExp = new RegExp('^' + prefix + '/(snippets)');

    Object.keys(requirejs.entries).filter(function (key) {
      return freestyleModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      var module = require(moduleName, null, null, true);
      var freestyleModule = module['default'];
      var moduleKey = moduleName.split('/').reverse()[0];
      var registryKey = 'config:ember-freestyle-' + moduleKey;
      application.register(registryKey, freestyleModule, { instantiate: false });
      application.inject('service:ember-freestyle', moduleKey, registryKey);
    });
  } /* global requirejs, require */
  exports.default = {
    name: 'ember-freestyle',
    initialize: initialize
  };
});