define("new-sounds-web-client/components/story-audio-embed/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['showSuccess:is-copied'],
    actions: {
      onSuccess: function onSuccess() {
        var _this = this;

        this.set('showSuccess', true);
        Ember.run.later(function () {
          _this.set('showSuccess', false);
        }, 2000);
      },
      onError: function onError() {}
    }
  });
});