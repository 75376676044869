define('new-sounds-web-client/services/listen-history', ['exports', 'nypr-audio-services/services/listen-history'], function (exports, _listenHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listenHistory.default;
    }
  });
});