define('new-sounds-web-client/models/musician', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)(),
    slug: (0, _attr.default)(),
    person: (0, _attr.default)()
  });
});