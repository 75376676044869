define('new-sounds-web-client/transforms/object', ['exports', 'ember-data', 'nypr-publisher-lib/helpers/camelize-object'], function (exports, _emberData, _camelizeObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function dasherizeObject(source) {
    var dest = null;

    if (Array.isArray(source)) {
      dest = source.map(function (obj) {
        return dasherizeObject(obj);
      });
    } else if (source !== null && (typeof source === 'undefined' ? 'undefined' : _typeof(source)) === "object") {
      dest = {};
      for (var prop in source) {
        var newKey = prop.dasherize();
        dest[newKey] = dasherizeObject(source[prop]);
      }
    } else {
      dest = source;
    }

    return dest;
  }

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _camelizeObject.camelizeObject)(serialized);
    },
    serialize: function serialize(deserialized) {
      return dasherizeObject(deserialized);
    }
  });
});