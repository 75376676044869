define('new-sounds-web-client/helpers/show-host', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.showHost = showHost;
  function showHost(params /*, hash*/) {
    var show = params[0];

    if (!show) {
      return {};
    }

    var people = Ember.makeArray(Ember.get(show, 'about.people'));

    var host = people.find(function (p) {
      return Ember.get(p, 'jobTitle').match(/host/i);
    });

    if (host) {
      return {
        name: Ember.get(host, 'name'),
        jobTitle: Ember.get(host, 'jobTitle'),
        image: Ember.get(host, 'image'),
        slug: Ember.get(host, 'slug')
      };
    }
  }

  exports.default = Ember.Helper.helper(showHost);
});