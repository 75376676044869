define('new-sounds-web-client/components/nypr-player/time-label', ['exports', 'nypr-player/components/nypr-player/time-label'], function (exports, _timeLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timeLabel.default;
    }
  });
});