define('new-sounds-web-client/models/airing', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _emberData, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startTime: (0, _attr.default)(),
    endTime: (0, _attr.default)(),
    showSlug: (0, _attr.default)(),
    showId: (0, _attr.default)(),
    show: (0, _relationships.belongsTo)({ async: false }),
    episode: (0, _relationships.belongsTo)('story', { async: true }),
    tracks: (0, _attr.default)(),
    isCurrent: Ember.computed('startTime', function () {
      return (0, _moment.default)(Ember.get(this, 'startTime')).format("X") <= (0, _moment.default)().format("X");
    })
  });
});