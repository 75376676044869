define('new-sounds-web-client/components/data-loader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasLoaded: true,
    init: function init() {
      Ember.set(this, 'extraParams', Ember.getWithDefault(this, 'extraParams', {}));
      Ember.set(this, 'page', Ember.getWithDefault(this, 'page', 1));
      Ember.set(this, 'pageSize', Ember.getWithDefault(this, 'pageSize', 12));
      Ember.set(this, 'hasMore', false);
      Ember.set(this, 'isLoading', true);

      this.resetState();
      this.on('didUpdateAttrs', this.resetState);
      this._super.apply(this, arguments);
    },
    resetState: function resetState() {
      Ember.set(this, 'page', 1);
      Ember.set(this, 'items', []);

      var initialData = Ember.get(this, 'initialData');
      if (initialData) {
        this.send('processResults', initialData);
        this.set('isLoading', false);
      } else {
        Ember.set(this, 'hasLoaded', false);
        this.send('fetchData');
      }
    },


    actions: {
      fetchData: function fetchData() {
        var _this = this;

        var pageSize = Ember.get(this, 'pageSize');
        var page = Ember.get(this, 'page');
        this.set('isLoading', true);
        var fetchParams = Ember.merge(Ember.get(this, 'extraParams'), { pageSize: pageSize, page: page });
        var fetch = Ember.get(this, 'fetch');
        fetch(fetchParams).then(function (results) {
          return _this.send('processResults', results);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      processResults: function processResults(results) {
        this.get('items').pushObjects(results.toArray());
        this.set('hasMore', Ember.get(results, 'meta.pagination.count') > Ember.get(this, 'items.length'));
        this.set('hasLoaded', true);

        if (this.get('hasMore')) {
          this.incrementProperty('page');
        }
      }
    }

  });
});