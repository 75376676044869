define('new-sounds-web-client/routes/artist-detail', ['exports', 'ember-data', 'ember-cli-string-helpers/helpers/underscore'], function (exports, _emberData, _underscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return [model.artist.get('name'), "Artists"];
    },
    model: function model(_ref) {
      var _this = this;

      var slug = _ref.slug;

      var fetchStories = Ember.run.bind(this, this.actions.fetchStories);

      return fetchStories({ pageSize: 12, page: 1, slug: slug }).then(function (stories) {
        return Ember.RSVP.hash({
          artist: _this.store.findRecord('musician', slug),
          stories: stories,
          storiesCount: stories.meta.pagination.count
        });
      }).catch(function (e) {
        if (e instanceof _emberData.default.NotFoundError) {
          _this.transitionTo('not-found', 'artists/' + slug);
        } else {
          throw e;
        }
      });
    },


    actions: {
      fetchStories: function fetchStories(_ref2) {
        var pageSize = _ref2.pageSize,
            page = _ref2.page,
            slug = _ref2.slug;

        return this.store.query("story", {
          tags: (0, _underscore.underscore)([slug]),
          item_type: ["episode", "article"].join(","),
          ordering: "-newsdate",
          page_size: pageSize,
          page: page,
          show: ["gig-alerts", "soundcheck", "newsounds", "helga"].join(","),
          channel: ["q2-album-week", "music-hub", "soundcheck-blog", "soundcheck-web-extras", "new-sounds"].join(",")
        });
      }
    }
  });
});