define('new-sounds-web-client/components/playlist-item', ['exports', 'nypr-publisher-lib/components/playlist-item', 'new-sounds-web-client/templates/components/playlist-item'], function (exports, _playlistItem, _playlistItem2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _playlistItem.default.extend({
    classNames: ['playlistitem-row'],
    layout: _playlistItem2.default
  });
});