define('new-sounds-web-client/components/listen-button/component', ['exports', 'nypr-audio-services/components/listen-button', 'nypr-audio-services/templates/components/listen-button'], function (exports, _listenButton, _listenButton2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _listenButton.default.extend({
    layout: _listenButton2.default,
    classNames: ['new-sounds-listen-button']
  });
});