define('new-sounds-web-client/components/stream-playlist', ['exports', 'nypr-publisher-lib/components/stream-playlist', 'new-sounds-web-client/templates/components/stream-playlist'], function (exports, _streamPlaylist, _streamPlaylist2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _streamPlaylist.default.extend({
    layout: _streamPlaylist2.default,
    isLoading: Ember.computed.empty('historyItems')
  });
});