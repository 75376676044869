define('new-sounds-web-client/models/story', ['exports', 'nypr-publisher-lib/models/story', 'ember-data/attr'], function (exports, _story, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _story.default.extend({
    appearances: (0, _attr.default)('object'),
    shortTitle: (0, _attr.default)('string'),
    shareMetadata: Ember.computed(function () {
      var title = Ember.get(this, 'twitterHeadline') || Ember.get(this, 'title');
      var parentTitle = Ember.get(this, 'headers.brand.title');
      var shareUrl = Ember.get(this, 'url');

      var shareText = 'I\'m listening to ' + [parentTitle, title].filter(function (t) {
        return t;
      }).join(' - ');
      var via = Ember.get(this, 'twitterHandle') || 'newsounds';

      return { shareText: shareText, shareUrl: shareUrl, via: via };
    })
  });
});