define('new-sounds-web-client/transitions', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.setDefault({ duration: duration(250) });
    this.transition(this.hasClass('nypr-player-wrapper'), this.use('playerReveal'));
    this.transition(this.hasClass('player-notification-wrapper'), this.use('notificationReveal'));
  };

  function duration(time) {
    return _environment.default.environment === 'test' ? 0 : time;
  }
});