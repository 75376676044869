define("new-sounds-web-client/helpers/progressive-image-loading-url", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.progressiveImageLoadingUrl = progressiveImageLoadingUrl;
  function progressiveImageLoadingUrl(url /*, hash*/) {
    if (url) {
      return url.replace(/\/(\d+)\/(\d+)\//, function (match, width, height) {
        return "/" + Math.floor(width / 20) + "/" + Math.floor(height / 20) + "/";
      });
    }
  }

  exports.default = Ember.Helper.helper(progressiveImageLoadingUrl);
});