define('new-sounds-web-client/helpers/buy-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buyUrl = buyUrl;
  function buyUrl(asin) {
    var associatesId = 'wnycorg-20';
    return 'http://www.amazon.com/exec/obidos/ASIN/' + asin + '/' + associatesId;
  }

  exports.default = Ember.Helper.helper(buyUrl);
});