define('new-sounds-web-client/helpers/url-encode', ['exports', 'nypr-ui/helpers/url-encode'], function (exports, _urlEncode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _urlEncode.default;
    }
  });
  Object.defineProperty(exports, 'urlEncode', {
    enumerable: true,
    get: function () {
      return _urlEncode.urlEncode;
    }
  });
});