define('new-sounds-web-client/routes/videos', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "New Sounds Videos",

    model: function model() {
      return Ember.RSVP.hash({
        dailyVideo: (0, _getBucket.default)(this.store, "newsounds-daily-video").then(function (b) {
          return Ember.get(b, "bucketItems.firstObject");
        })
      });
    },


    actions: {
      loadPage: function loadPage(page) {
        this.transitionTo('videos.page', page);
        return true;
      }
    }
  });
});