define('new-sounds-web-client/helpers/capitalize-word', ['exports', 'nypr-publisher-lib/helpers/capitalize-word'], function (exports, _capitalizeWord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _capitalizeWord.default;
    }
  });
  Object.defineProperty(exports, 'capitalizeWord', {
    enumerable: true,
    get: function () {
      return _capitalizeWord.capitalizeWord;
    }
  });
});