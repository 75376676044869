define('new-sounds-web-client/components/all-shows-grid/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['all-shows-grid'],
    hasLoaded: true,
    featuredItems: Ember.A(),
    liveStream: null,

    init: function init() {
      this.resetState();
      this._super.apply(this, arguments);
    },
    resetState: function resetState() {
      Ember.set(this, 'featuredItems', []);
      Ember.set(this, 'liveStream', null);
      Ember.set(this, 'hasLoaded', false);
      Ember.set(this, 'isLoading', true);
      this.send('fetchData');
    },


    actions: {
      fetchData: function fetchData() {
        var _this = this;

        this.get('fetch')().then(function (results) {
          return _this.send('processResults', results);
        }).finally(function () {
          Ember.set(_this, 'isLoading', false);
        });
      },
      processResults: function processResults(results) {
        Ember.set(this, 'featuredItems', results.featuredItems.toArray());
        Ember.set(this, 'liveStream', results.liveStream);
        Ember.set(this, 'hasLoaded', true);
      }
    }
  });
});