define('new-sounds-web-client/helpers/color-changer', ['exports', 'tinycolor'], function (exports, _tinycolor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.colorChanger = colorChanger;
  function colorChanger(params /*, hash*/) {
    if (params[1] && params[1] < 0) {
      return (0, _tinycolor.default)(params[0]).darken(params[1] * -1).toString();
    } else if (params[1] && params[1] > 0) {
      return (0, _tinycolor.default)(params[0]).lighten(params[1]).toString();
    }

    return (0, _tinycolor.default)(params[0]).toString();
  }

  exports.default = Ember.Helper.helper(colorChanger);
});