define('new-sounds-web-client/components/admin-edit-link/component', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    adminURL: _emberGetConfig.default.adminRoot + '/admin',
    isStaff: Ember.computed.reads('session.data.isStaff'),
    editLink: "",
    href: Ember.computed('adminUrl', 'editLink', function () {
      return this.get('adminURL') + '/' + this.get('editLink') + '/';
    })
  });
});