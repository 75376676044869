define('new-sounds-web-client/components/story-detail', ['exports', 'nypr-publisher-lib/components/story-detail'], function (exports, _storyDetail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _storyDetail.default;
    }
  });
});