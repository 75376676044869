define('new-sounds-web-client/components/newsletter-signup/component', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validations = {
    legal: (0, _validators.validatePresence)(true)
  };

  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['newsletter-signup'],
    formLoading: false,
    successMsg: null,
    errorMsg: null,

    init: function init() {
      this._super.apply(this, arguments);
      var changeset = new _emberChangeset.default({ legal: true }, (0, _emberChangesetValidations.default)(validations), validations);
      Ember.set(this, 'changeset', changeset);
    },


    disableSubmit: Ember.computed('changeset.legal', function () {
      if (this.changeset.get('legal')) {
        // if legal is true (i.e. checked)
        return false;
      } else {
        return true;
      }
    }),

    hasErrors: function hasErrors() {
      var emailAddress = this.get("email");
      if (!emailAddress) {
        this.set('errorMsg', "Please enter your email address");
        return true;
      }
      if (this.validateEmail(emailAddress)) {
        this.set('errorMsg', null);
        return false;
      } else {
        this.set('errorMsg', "The email address you entered doesn't look correct.");
        return true;
      }
    },

    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    actions: {
      submitForm: function submitForm() {
        if (!this.hasErrors()) {
          this.set("formLoading", true);
          var options = {
            type: 'GET',
            url: "https://wqxr.us5.list-manage.com/subscribe/post-json?u=4109fdd323aaac7078eadaa8f&amp;id=fa9d482354",
            dataType: 'jsonp',
            jsonp: 'c',
            data: this.$('form').serialize()
          };
          Ember.$.ajax(options).always(function (response) {
            if (response.result === "error") {
              this.set('formLoading', false);
              this.set('errorMsg', Ember.String.htmlSafe(response.msg));
            } else {
              this.set('formLoading', false);
              this.set('successMsg', Ember.String.htmlSafe(response.msg));
            }
          }.bind(this));
        }
      },

      //release the error state as the user enters text
      releaseErrorState: function releaseErrorState() {
        this.set('errorMsg', null);
      }

    }
  });
});