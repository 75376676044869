define('new-sounds-web-client/components/nypr-ui/brand-header/header-left', ['exports', 'nypr-ui/components/nypr-ui/brand-header/header-left'], function (exports, _headerLeft) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _headerLeft.default;
    }
  });
});