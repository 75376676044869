define("new-sounds-web-client/adapters/musician", ["exports", "new-sounds-web-client/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return "musician";
    }
  });
});