define("new-sounds-web-client/routes/show-detail", ["exports", "ember-data", "new-sounds-web-client/config/environment"], function (exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    customColorStore: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return [model.show.get('title'), 'New Sounds Music Shows', 'Hand-picked music, genre free'];
    },

    model: function model(_ref) {
      var _this = this;

      var slug = _ref.slug;

      return Ember.RSVP.hash({
        show: this.store.findRecord("show", slug)
      }).catch(function (e) {
        if (e instanceof _emberData.default.NotFoundError) {
          _this.transitionTo('not-found', "shows/" + slug);
        } else {
          throw e;
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      if (model.show && _environment.default.environment !== 'development') {
        var canonicalHost = Ember.get(model.show, 'canonicalHost');
        if (canonicalHost && canonicalHost !== document.location.host) {
          transition.abort();
          window.location.href = Ember.get(model.show, 'url');
          return;
        }
      }

      this.get('customColorStore').update();
    },


    actions: {
      loadPage: function loadPage(page) {
        this.transitionTo('show-detail.page', page);

        return true;
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        Ember.get(this, 'dataLayer').setForType('show', this.currentModel.show);
        return true;
      },
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        Ember.get(this, 'dataLayer').clearForType('show');
        return true;
      }
    }
  });
});