define('new-sounds-web-client/components/show-grid-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['shows-grid-item'],
    attributeBindings: ['colorKey:data-custom-color']
  });
});