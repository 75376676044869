define('new-sounds-web-client/helpers/casual-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.casualTime = casualTime;
  function casualTime(params /*, hash*/) {
    var time = (0, _moment.default)(params[0]);

    if (time.format('mm') === '00') {
      return time.format('hA');
    } else {
      return time.format('h:mmA');
    }
  }

  exports.default = Ember.Helper.helper(casualTime);
});