define('new-sounds-web-client/routes/story', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    titleToken: function titleToken(_ref) {
      var story = _ref.story;

      if (story.get('showTitle')) {
        return [story.get('title'), story.get('showTitle')];
      }
      return story.get('title');
    },
    model: function model(_ref2, _ref3) {
      var _this = this;

      var slug = _ref2.slug;
      var queryParams = _ref3.queryParams;

      return this.store.findRecord('story', slug, { adapterOptions: { queryParams: queryParams } }).then(function (story) {
        var show = {};
        var storyShow = story.get('show');

        if (storyShow) {
          show = _this.store.findRecord('show', storyShow);
        } else {
          show = _this.store.findRecord('show', 'newsounds');
        }

        return Ember.RSVP.hash({
          story: story,
          show: show
        });
      }).catch(function (e) {
        if (e instanceof _emberData.default.NotFoundError) {
          _this.transitionTo('not-found', 'story/' + slug);
        } else {
          throw e;
        }
      });
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        Ember.get(this, 'dataLayer').setForType('story', this.currentModel.story);
        return true;
      },
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        Ember.get(this, 'dataLayer').clearForType('story');
        return true;
      }
    }
  });
});