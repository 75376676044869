define('new-sounds-web-client/instance-initializers/youtube-embed-api', ['exports', 'new-sounds-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    if (typeof window !== 'undefined' && _environment.default.environment !== 'test') {
      var script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.async = true;
      var firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  }

  exports.default = {
    initialize: initialize
  };
});