define('new-sounds-web-client/routes/index', ['exports', 'new-sounds-web-client/mixins/fetch-news-action', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _fetchNewsAction, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fetchNewsAction.default, {
    titleToken: "Home Page",
    model: function model() {
      var fetchNews = Ember.run.bind(this, this.actions.fetchNews);

      return Ember.RSVP.hash({
        articles: fetchNews({ pageSize: 4, page: 1 }),
        bucket: (0, _getBucket.default)(this.store, 'newsounds-featured-home'),
        gigAlerts: this.store.query("story", {
          show: 'gig-alerts',
          item_type: "episode",
          ordering: "-newsdate",
          audio_only: 1,
          page_size: 4
        }),
        dailyVideo: (0, _getBucket.default)(this.store, "newsounds-daily-video")
      }).then(function (results) {
        return {
          articles: Ember.get(results, 'articles'),
          featuredArticle: Ember.get(results, 'articles.firstObject'),
          featuredEditLink: Ember.get(results, 'bucket.editLink'),
          featuredItems: Ember.get(results, 'bucket.bucketItems').slice(0, 3),
          gigAlerts: Ember.get(results, 'gigAlerts'),
          dailyVideo: Ember.get(results, 'dailyVideo.bucketItems.firstObject')
        };
      });
    },


    actions: {
      fetchEpisodes: function fetchEpisodes(_ref) {
        var _this = this;

        var pageSize = _ref.pageSize,
            page = _ref.page;

        return (0, _getBucket.default)(this.store, 'newsounds-featured-shows').then(function (_ref2) {
          var bucketItems = _ref2.bucketItems;

          return _this.store.query("story", {
            show: bucketItems.map(function (i) {
              return i.attributes.slug;
            }).join(","),
            item_type: "episode",
            ordering: "-newsdate",
            page_size: pageSize,
            audio_only: 1,
            page: page
          });
        });
      }
    }
  });
});