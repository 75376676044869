define('new-sounds-web-client/router', ['exports', 'new-sounds-web-client/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('news', { path: 'features' });
    this.route('freestyle', { path: 'styleguide' });
    this.route('about');
    this.route('shows');
    this.route('archive', { path: 'shows/:uslug/archive' });
    this.route('show-detail', { path: 'shows/:slug' }, function () {
      this.route('page', { path: ':page' });
    });
    this.route('playlist', { path: 'livestream' });
    this.route('story', { path: 'story/:slug' });
    this.route('person-detail', { path: 'people/:slug' });
    this.route('search');
    this.route('server-errors', { path: '/error' }, function () {
      this.route('internal-error', { path: '/500' });
    });
    this.route('artists');
    this.route('artist-detail', { path: 'artists/:slug' });
    this.route('videos', function () {
      this.route('page', { path: ':page' });
    });

    this.route('not-found', { path: '*url' });
  });

  exports.default = Router;
});