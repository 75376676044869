define('new-sounds-web-client/models/show', ['exports', 'nypr-publisher-lib/models/show', 'ember-data/attr', 'ember-data/relationships'], function (exports, _show, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _show.default.extend({
    about: (0, _attr.default)('object'),
    marqueeImage: (0, _relationships.belongsTo)('image'),
    airings: (0, _relationships.hasMany)({ async: false })
  });
});