define('new-sounds-web-client/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    chromeDisabled: false,
    dj: Ember.inject.service(),
    hifi: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    showPlayer: false,
    session: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this.get('hifi').on('new-load-request', function () {
        return _this.set('showPlayer', true);
      });

      // We don't have user prefs and `nypr-audio-services` defaults to `wnyc-fm`, so let's set these defaults
      this.get('session').set('data.user-prefs-active-autoplay', 'default_stream');
      this.get('session').set('data.user-prefs-active-stream', { slug: 'q2', name: 'the New Sounds livestream' });

      this.set('emberChimpResponses', {
        success: 'Please click the link in the email we just sent you.',
        error: 'Oops, something went wrong.  Please try again.',
        invalidError: 'Please enter a valid email address.',
        attemptsError: 'Please try again in about 5 minutes.'
      });

      this._super.apply(this, arguments);
    },


    actions: {
      soundTitleDidChange: function soundTitleDidChange() {
        var sound = this.get('hifi.currentSound');
        if (Ember.get(sound, 'isStream') && Ember.get(sound, 'position') > 10) {
          // skip initial play
          this.get('dataLayer').audioTracking('schedule', sound);
        }
      }
    }
  });
});