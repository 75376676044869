define('new-sounds-web-client/routes/shows', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "New Sounds Music Shows",
    model: function model() {
      var _this = this;

      return (0, _getBucket.default)(this.store, 'newsounds-featured-shows').then(function (b) {
        var featuredItems = Ember.get(b, 'bucketItems');
        var featuredSlugs = featuredItems.map(function (i) {
          return i.attributes.slug;
        });
        return Ember.RSVP.hash({
          featuredItems: featuredItems,
          liveStream: _this.store.findRecord('stream', 'q2'),
          otherShows: (0, _getBucket.default)(_this.store, 'newsounds-more-shows').then(function (b) {
            return Ember.get(b, 'bucketItems');
          }),
          episodes: _this.store.query('story', { show: featuredSlugs.join(","), item_type: 'episode', ordering: '-newsdate', page_size: 12, audio_only: 1 })
        });
      });
    }
  });
});