define('new-sounds-web-client/components/stream-banner/dropdown', ['exports', 'nypr-audio-services/components/stream-banner/dropdown'], function (exports, _dropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dropdown.default;
    }
  });
});