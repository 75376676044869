define('new-sounds-web-client/routes/about', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "About New Sounds",
    model: function model() {
      return Ember.RSVP.hash({
        people: (0, _getBucket.default)(this.store, 'newsounds-people-about').then(function (b) {
          return Ember.get(b, 'bucketItems');
        })
      });
    }
  });
});