define('new-sounds-web-client/routes/artists', ['exports', 'new-sounds-web-client/helpers/get-bucket'], function (exports, _getBucket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Artists",
    model: function model() {
      var fetchBucket = Ember.run.bind(this, this.actions.fetchBucket);
      return Ember.RSVP.hash({
        artists: fetchBucket({ page: 1, pageSize: 27 })
      });
    },


    actions: {
      fetchBucket: function fetchBucket(_ref) {
        var pageSize = _ref.pageSize,
            page = _ref.page;

        // Fake paging
        return (0, _getBucket.default)(this.store, 'newsounds-artists').then(function (artistsBucket) {
          var items = Ember.get(artistsBucket, 'bucketItems');
          var currentPageItems = items.slice((page - 1) * pageSize, page * pageSize);

          var payload = Ember.A(currentPageItems);
          payload.set('meta', {
            pagination: {
              count: items.length
            }
          });

          return payload;
        });
      }
    }
  });
});